import React from "react";
import WhoWeAre from "../components/AboutUsPage/WhoWeAre";
import BreadCrumb from "../components/common/BreadCrumb";
import Navbar from "../components/common/preRegistration/Navbar";
import Footer from "../components/common/preRegistration/Footer";
import MetaTags from "../components/common/MetaTags";

function AboutUs() {
  return (
    <>
      <MetaTags
        title="About Us / Aindrea NFT Marketplace"
        description="Learn more about Aindrea, the cutting-edge NFT marketplace that empowers creators and collectors to engage in a secure and seamless digital asset experience."
        url="/about-us"
      />
      <Navbar />
      <BreadCrumb
        title="About Us"
        img={"/assets/images/aboutus/about-us.png"}
      />
      <WhoWeAre />
      {/* <Team /> */}
      <Footer />
    </>
  );
}

export default AboutUs;
